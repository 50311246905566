import Img from "gatsby-image"
import ContactForm from "../components/ContactForm/ContactForm"
import Layout from "../components/Layout"
import React from "react"
import SEO from "../components/seo"

const QuotePage = ({ data }) => {
  const {
    content,
    header_image,
    title,
    quote_image_1,
    quote_image_2,
    quote_image_3,
    quote_image_4,
  } = data.allPrismicQuotePage.edges[0].node.data
  return (
    <Layout>
      <SEO title="Book Online Today" />
      <div className="quote-page__image-container">
        <div className="quote-page__title">
          <h1 className="quote-page__title-text">Get a Quote</h1>
          <h2 className="quote-page__subtitle-text">Scroll down to enquire</h2>
        </div>
        <Img fluid={header_image.fluid} />
      </div>
      <div className="quote-page">
        <div className="quote-page__content-container">
          <div className="quote-page__content-container--col1">
            <h2>{title.text}</h2>
            <div dangerouslySetInnerHTML={{ __html: content.html }}></div>
          </div>
          <div className="quote-page__content-container--col2">
            <div className="quote-page__content-container--col2--image-container">
              <Img fluid={quote_image_1.fluid} />
              <Img fluid={quote_image_2.fluid} />
              <Img fluid={quote_image_3.fluid} />
              <Img fluid={quote_image_4.fluid} />
            </div>
          </div>
        </div>
        <div className="quote-page__form-container">
          <ContactForm type="quote" />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query QuotePageQuery {
    allPrismicQuotePage {
      edges {
        node {
          data {
            content {
              html
            }
            header_image {
              fluid(maxWidth: 1000) {
                src
                srcWebp
                srcSetWebp
                srcSet
                sizes
                base64
                aspectRatio
              }
            }
            title {
              text
            }
            quote_image_1 {
              fluid(maxWidth: 1000) {
                src
                srcWebp
                srcSetWebp
                srcSet
                sizes
                base64
                aspectRatio
              }
            }
            quote_image_2 {
              fluid(maxWidth: 1000) {
                src
                srcWebp
                srcSetWebp
                srcSet
                sizes
                base64
                aspectRatio
              }
            }
            quote_image_3 {
              fluid(maxWidth: 1000) {
                src
                srcWebp
                srcSetWebp
                srcSet
                sizes
                base64
                aspectRatio
              }
            }
            quote_image_4 {
              fluid(maxWidth: 1000) {
                src
                srcWebp
                srcSetWebp
                srcSet
                sizes
                base64
                aspectRatio
              }
            }
          }
        }
      }
    }
  }
`

export default QuotePage
